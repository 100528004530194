import { useAsyncData } from '#imports'
import { useStore } from 'vuex'

/**
 * useAsyncAction is used to deduplicate async actions dispatched to Vuex and avoid hydration mismatch refetching on the front-end
 * Usage: useAsyncAction('my/action', args?)
 */
export default function(...args){
  const store = useStore()
  /* 
    useAsyncData needs a unique key since it can't generate them by itself (called on the same file / same line)
    We use the action name (first argument) as unique key
    see https://nuxt.com/docs/api/composables/use-async-data#params
  */
  
  // useAsyncData handler return value must be truthy
  return useAsyncData(args[0], () => store.dispatch(...args).then(data => data ?? true))
}
